<template>
  <div class="pa-5 white text-body-3">
    <p class="text-center">
      {{ $t('notification.confirmDeleteFromCart') }}
    </p>
    <base-button
      class="white--text"
      :class="$config.isMaBuBuild ? 'lilac-pastel' : 'rose-pastel'"
      large
      rounded
      block
      @click="$emit('click:remove')"
    >
      {{ $t('commons.deleteProduct') }}
    </base-button>
    <base-button
      class="apricot-pastel white--text mt-3"
      large
      rounded
      block
      @click="$emit('click:wishlist')"
      >{{ $t('commons.addToWishlist') }}</base-button
    >
    <base-button
      large
      rounded
      outlined
      block
      class="mt-3"
      @click="$emit('click:cancel')"
      >{{ $t('actions.cancel') }}</base-button
    >
  </div>
</template>

<script>
import BaseButton from '~/components/base/button/BaseButton'
export default {
  name: 'CartDeleteCard',
  components: { BaseButton },
}
</script>
