<template>
  <v-form ref="form" @submit.prevent="submit">
    <base-input-text-field
      v-model.trim="form.code"
      name="voucherCode"
      class="text-body-3"
      solo
      autocomplete="off"
      :error-messages="errorMessage"
      v-bind="$attrs"
      :loading="promotionCodeIsLoading"
      hide-details="auto"
      :label="$t('cart.labelVoucher')"
      append-icon="$next"
      @click:append="submit(form.code)"
      @focus="$emit('focus')"
    ></base-input-text-field>
  </v-form>
</template>

<script>
import { ref } from '@nuxtjs/composition-api'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useCart,
  useIntercept,
} from '~/composables'
import BaseInputTextField from '~/components/base/input/BaseInputTextField'

export default {
  name: 'CartSubmitPromotion',
  components: { BaseInputTextField },
  setup(_, { root }) {
    const { addPromotionCode: submitPromotionCode } = useCart(root)
    const { intercept } = useIntercept(root)
    const { i18n } = getApplicationContext(root)

    const errorMessage = ref(null)
    const promotionCodeIsLoading = ref(false)
    const timeoutId = ref(null)

    const addPromotionCode = async (promotion) => {
      errorMessage.value = null
      promotionCodeIsLoading.value = true
      try {
        await submitPromotionCode(promotion)

        return true
      } catch (e) {
        return false
      } finally {
        promotionCodeIsLoading.value = false
      }
    }

    intercept(INTERCEPTOR_KEYS.ADD_PROMOTION_CODE, ({ result }) => {
      // @ts-ignore
      const errorKey = Object.values(result?.errors || {})?.[0]

      if (errorKey) {
        errorMessage.value = null

        const message = i18n.t(`backend.${errorKey.messageKey}`, [
          errorKey.promotionCode || errorKey.name,
        ])

        switch (errorKey.messageKey) {
          case 'promotion-discount-added':
            break
          default:
            errorMessage.value = message
            if (timeoutId.value) {
              clearTimeout(timeoutId.value)
            }

            setTimeout(() => {
              errorMessage.value = null
              timeoutId.value = null
            }, 6000)
            break
        }
      }
    })

    return {
      addPromotionCode,
      promotionCodeIsLoading,
      errorMessage,
    }
  },
  data() {
    return {
      showVoucherField: false,
      form: {
        code: undefined,
      },
    }
  },
  methods: {
    async submit() {
      if (!this.form.code) {
        return
      }
      const success = await this.addPromotionCode(this.form.code)

      if (success) {
        this.resetForm()
      }
    },

    resetForm() {
      this.$refs.form?.reset()
    },
  },
}
</script>
