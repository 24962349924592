<template>
  <nuxt-link
    class="cart-product-item-image text-decoration-none grey-darken-4--text"
    :to="localePath(url)"
  >
    <product-image
      :src="imageUrl"
      class="cart-product-item-image__image"
      gradient
    >
    </product-image>
    <div
      v-if="individualisation"
      class="cart-product-item-image__image-icon lilac-pastel"
    >
      <base-icon x-large mdi="individualisation" color="white"></base-icon>
    </div>
  </nuxt-link>
</template>

<script>
import ProductImage from '~/components/product/ProductImage'
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'CartProductItemImage',
  components: { BaseIcon, ProductImage },
  props: {
    url: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    individualisation: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.cart-product-item-image {
  position: relative;

  &__image {
    width: 96px;
    max-width: 96px;
    overflow: visible;
  }

  &__image-icon {
    padding: 4px;
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 50%;
    color: white;
  }
}
</style>
