import { render, staticRenderFns } from "./CartProductItemImage.vue?vue&type=template&id=60b93492&"
import script from "./CartProductItemImage.vue?vue&type=script&lang=js&"
export * from "./CartProductItemImage.vue?vue&type=script&lang=js&"
import style0 from "./CartProductItemImage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports