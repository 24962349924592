<script>
import { VTextField } from 'vuetify/lib'

export default {
  name: 'BaseInputTextField',
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    forceOutlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ...VTextField.options.computed.classes.call(this),
        'base-input-text-field': true,
        'base-input-text-field--force-outlined': this.forceOutlined,
        'v-text-field--solo-flat': this.solo,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.base-input-text-field {
  &.v-text-field--outlined {
    &:not(.v-input--is-focused):not(.v-input--is-label-active):not(.base-input-text-field--force-outlined) {
      > .v-input__control {
        > .v-input__slot {
          background: map-get($shades, 'grey-lighten-4');

          &:hover {
            background: map-get($shades, 'grey-lighten-3');
          }
        }
      }

      fieldset {
        border: none !important;
      }
    }

    &:not(.v-input--is-focused):not(.v-input--has-state) {
      fieldset {
        color: map-get($shades, 'grey-lighten-1') !important;
      }
    }

    &.error--text fieldset {
      border-color: map-get($shades, 'pink-berry');
    }
  }

  .v-progress-linear {
    background: map-get($shades, 'grey-lighten-1') !important;
  }

  input {
    color: currentColor !important;
  }

  .v-label {
    font-size: 13px;
  }
}
</style>
