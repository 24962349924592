<template>
  <div v-if="shouldShowComponent">
    <template v-if="$i18n.locale !== 'fr-FR'">
      <div class="d-flex align-center">
        <base-icon
          class="green-smaragd white--text rounded-circle"
          mdi="check"
          size="18"
          style="padding: 2px"
        ></base-icon>
        <span class="green-smaragd--text pl-2" :class="textClass">{{
          $t('productDetailPage.freeShipping20Euro')
        }}</span>
      </div>
    </template>
    <template v-else>
      <span v-if="progressValue" :class="textClass">
        {{
          $t('productDetailPage.progressValue', {
            progressValue: $options.filters.price(progressValue),
          })
        }}</span
      >
      <span v-else :class="textClass">
        {{ $t('productDetailPage.freeShipping') }}</span
      >
      <base-progress-linear
        class="mt-2"
        style="border-radius: 32px; max-width: 450px"
        background-class="grey-lighten-2"
        :foreground-class="progressColor"
        :value="progress"
        :aria-label="$t('productDetailPage.freeShippingIn')"
        height="12px"
      ></base-progress-linear>
    </template>
  </div>
</template>

<script>
import { useFreeShippingHint } from '~/composables'
import BaseProgressLinear from '~/components/base/progress/BaseProgressLinear'
import BaseIcon from '~/components/base/icon/BaseIcon.vue'

export default {
  name: 'ProductDetailFreeShippingCard',
  components: { BaseIcon, BaseProgressLinear },
  props: {
    textClass: {
      type: String,
      default: 'text-body-4 text-md-body-2',
    },
  },
  setup(_, { root }) {
    const { progressValue, progress, shouldShowComponent } =
      useFreeShippingHint(root)

    return {
      progressValue,
      progress,
      shouldShowComponent,
    }
  },
  computed: {
    progressColor() {
      return this.progress === 100 ? 'green-smaragd' : 'rose-pastel'
    },
  },
}
</script>
