<template>
  <span class="pink-berry--text text-body-4">{{
    $t('cart.lowStock.availableStock', { stock: stock })
  }}</span>
</template>

<script>
export default {
  name: 'CartLowStock',
  props: {
    stock: {
      type: Number,
      default: 0,
    },
  },
}
</script>
