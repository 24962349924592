<template>
  <base-expansion-panel
    class="cart-product-item-individualisation"
    :title="$t('cart.personalisation')"
    title-class="text-body-3 grey-lighten-3 px-3 py-1"
  >
    <div :class="contentClass" class="grey-lighten-4 pa-3">
      <v-row no-gutters>
        <v-col
          v-for="indi in individualisationPayload"
          :key="indi.key"
          cols="6"
        >
          <span class="text-h6">{{ indi.value }}:</span>
          <span class="text-body-4">{{ indi.text }}</span>
        </v-col>
      </v-row>
    </div>
  </base-expansion-panel>
</template>

<script>
import { getCartItemProductIndividualisationPayload } from '~/helpers'
import BaseExpansionPanel from '~/components/base/expansion/BaseExpansionPanel'

export default {
  name: 'CartProductItemIndividualisation',
  components: { BaseExpansionPanel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    individualisation: {
      type: Object,
      default: () => {},
    },
    contentClass: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    individualisationPayload() {
      return this.individualisation
        ? getCartItemProductIndividualisationPayload(
            this.individualisation,
            this.$i18n
          )
        : null
    },
  },
}
</script>

<style lang="scss">
.cart-product-item-individualisation {
  .base-expansion-panel__button {
    height: 32px;
  }
}
</style>
