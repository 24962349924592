<template>
  <div class="grey-lighten-4 base-input-stock-level">
    <base-button :disabled="shouldDisableDecrement" @click="decrement">
      <base-icon mdi="minus" small></base-icon>
    </base-button>

    <div class="base-input-stock-level__stock text-body-3">
      {{ internalValue }}
    </div>
    <base-button :disabled="shouldDisableIncrement" @click="increment">
      <base-icon mdi="plus" small></base-icon>
    </base-button>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
export default {
  name: 'BaseInputStockLevel',
  components: { BaseButton, BaseIcon },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    disableDecrement: {
      type: [Number, Boolean],
      default: false,
    },
    disableIncrement: {
      type: [Number, Boolean],
      default: false,
    },
  },

  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    shouldDisableDecrement() {
      if (typeof this.disableDecrement === 'boolean') {
        return this.disableDecrement
      }

      return this.value <= this.disableDecrement
    },
    shouldDisableIncrement() {
      if (typeof this.disableIncrement === 'boolean') {
        return this.disableIncrement
      }

      return this.value >= this.disableIncrement
    },
  },
  methods: {
    increment() {
      this.internalValue++

      this.$emit('increment')
    },
    decrement() {
      this.internalValue--

      this.$emit('increment')
    },
  },
}
</script>

<style lang="scss">
.base-input-stock-level {
  display: flex;
  align-content: center;
  width: 107px;
  align-items: center;

  &__stock {
    height: 34px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .base-button {
    height: 36px !important;
    width: 36px !important;
    min-width: 0 !important;
  }
}
</style>
