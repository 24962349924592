<template>
  <div class="d-flex align-center text-body-3 py-1" :class="color">
    <base-icon
      mdi="brightness-percent"
      color="pink-berry"
      class="mr-2"
    ></base-icon>
    {{ title }}
    <base-button icon class="ml-auto" @click="removeCartItem">
      <base-icon v-show="item.removable" mdi="delete"></base-icon>
    </base-button>
  </div>
</template>

<script>
import { useCart } from '~/composables'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CartPromotionItem',
  components: { BaseButton, BaseIcon },
  props: {
    item: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: 'grey-lighten-4',
    },
  },
  setup(_, { root }) {
    const { removeItem } = useCart(root)

    return {
      removeItem,
    }
  },
  computed: {
    payload() {
      return this.item.payload
    },
    title() {
      let value = ''

      switch (this.payload.discountType) {
        case 'percentage':
          value = `${this.payload.value} %`
          break
        case 'absolute':
          value = `${this.payload.value} €`
          break
      }

      if (!this.item.referencedId && this.item.label) {
        return this.item.label
      }

      return this.$t('voucherCode', {
        value,
        reference: this.item.referencedId,
      })
    },
  },
  methods: {
    removeCartItem() {
      this.removeItem(this.item)
    },
  },
}
</script>
