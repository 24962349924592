<template>
  <div>
    <div ref="button"></div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { usePaypalExpressCheckout } from '~/composables'

export default {
  name: 'PaymentPaypalExpressCheckoutButton',
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { createButton, setEventListener } = usePaypalExpressCheckout(root)
    const button = ref()

    onMounted(() => {
      createButton(button.value, {
        height: props.large ? 40 : 25,
      })

      setEventListener((event) => {
        switch (event) {
          case 'createOrder':
            emit('event:create-order')
            break
        }
      })
    })

    return {
      button,
    }
  },
}
</script>
