<template>
  <div class="text-body-4" :class="deliveryColor">
    {{ deliveryTime }}
  </div>
</template>

<script>
// TODO DeliveryTime plus Var in Übersetzungsstring
export default {
  name: 'CartProductItemDelivery',
  props: {
    item: {
      type: Object,
      default: null,
    },
    individualisation: {
      type: Object,
      default: null,
    },
  },
  computed: {
    deliveryTime() {
      if (this.individualisation) {
        if (
          this.individualisation.deliveryInformation &&
          this.individualisation.deliveryInformation.deliveryTime
        ) {
          return this.getDeliveryTimeString(
            this.individualisation.deliveryInformation
          )
        } else {
          return this.$t('cart.deliveryTime.deliveryTimeLong')
        }
      } else if (
        this.item.deliveryInformation &&
        this.item.deliveryInformation.deliveryTime
      ) {
        return this.getDeliveryTimeString(this.item.deliveryInformation)
      }

      return this.$t('cart.deliveryTime.immediatelyAvailable')
    },
    deliveryColor() {
      let color = 'green-smaragd--text'

      if (
        this.individualisation ||
        !!this.item.deliveryInformation?.deliveryTime
      ) {
        color = 'pink-berry--text'
      }

      return [color]
    },
  },
  methods: {
    getDeliveryTimeString({ deliveryTime }) {
      let unit = null

      switch (deliveryTime.unit) {
        case 'day':
          unit = this.$t('cart.deliveryTime.unitDays')
          break
        case 'week':
          unit = this.$t('cart.deliveryTime.unitWeeks')
          break
      }

      if (!unit) {
      }

      return this.$t('cart.deliveryTime.changesTo', {
        min: deliveryTime.min,
        max: deliveryTime.max,
        unit,
      })
    },
  },
}
</script>
