<template>
  <div v-if="crossSelling && crossSelling.products.length">
    <div :class="titleClass">{{ title }}</div>
    <product-slider
      v-intersect.once="onIntersect"
      :products="crossSelling.products"
      v-bind="$attrs"
      v-on="$listeners"
      @select_item="handleSelectItem"
    ></product-slider>
  </div>
</template>

<script>
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'

const ALGO_BS_MATCHING = 'bs_matching'
const ALGO_BS_SIMILAR = 'bs_similar'
const ALGO_BS_ALSO_BOUGHT = 'bs_also_bought'
const ALGO_BS_SPECIFICATION_TYPE = 'bs_specification_type'
const ALGO_BS_MANUFACTURER = 'bs_manufacturer'
const ALGO_BS_MANUFACTURER_COLLECTION = 'bs_manufacturer_collection'
const ALGO_ALSO_BOUGHT = 'ALSO_BOUGHT'

export default {
  name: 'ProductCrossSellingSelectorContainer',
  components: {
    ProductSlider: () => import('~/components/product/ProductSlider'),
  },
  props: {
    crossSellings: {
      type: Array,
      default: () => [],
    },
    titleClass: {
      type: String,
      default: '',
    },
    alternative: {
      type: Boolean,
      default: false,
    },
    alternative2: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const { broadcast } = useIntercept(root)

    return {
      broadcast,
    }
  },
  computed: {
    crossSelling() {
      return this.bestAlgorithmCrossSelling || this.fallbackCrossSelling
    },
    algorithms() {
      if (this.alternative2) {
        return [
          ALGO_BS_SPECIFICATION_TYPE,
          ALGO_BS_MANUFACTURER,
          ALGO_BS_ALSO_BOUGHT,
          ALGO_ALSO_BOUGHT,
          ALGO_BS_MANUFACTURER_COLLECTION,
          ALGO_BS_MATCHING,
          ALGO_BS_SIMILAR,
        ]
      }

      return this.alternative
        ? [
            ALGO_BS_MANUFACTURER_COLLECTION,
            ALGO_BS_MANUFACTURER,
            ALGO_BS_MATCHING,
            ALGO_BS_ALSO_BOUGHT,
            ALGO_ALSO_BOUGHT,
            ALGO_BS_SPECIFICATION_TYPE,
            ALGO_BS_SIMILAR,
          ]
        : [
            ALGO_BS_SIMILAR,
            ALGO_BS_MATCHING,
            ALGO_BS_ALSO_BOUGHT,
            ALGO_BS_MANUFACTURER_COLLECTION,
            ALGO_BS_MANUFACTURER,
            ALGO_BS_SPECIFICATION_TYPE,
            ALGO_ALSO_BOUGHT,
          ]
    },
    bestAlgorithmCrossSelling() {
      const cross = this.algorithms
        .map((type) => {
          return this.crossSellings?.find((crossSelling) => {
            return (
              (crossSelling.crossSelling.type === type ||
                crossSelling.crossSelling.translated.name === type) &&
              crossSelling.total > 4
            )
          })
        })
        .filter((c) => !!c)

      return cross.length > 0 ? cross[0] : null
    },
    fallbackCrossSelling() {
      return this.crossSellings?.reduce((acc, cur) => {
        return acc && acc.total > cur.total ? acc : cur
      }, null)
    },
    technicalName() {
      if (!this.crossSelling) {
        return null
      }

      return this.crossSelling.crossSelling.translated.name
    },
    title() {
      if (!this.technicalName) {
        return ''
      }

      switch (this.technicalName) {
        case ALGO_BS_SIMILAR:
          return this.$t('crossSelling.bsSimilar')
        case ALGO_BS_MATCHING:
        case ALGO_BS_SPECIFICATION_TYPE:
          return this.$t('crossSelling.bsMatching')
        case ALGO_BS_ALSO_BOUGHT:
          return this.$t('crossSelling.typeAlsoBought')
        case ALGO_BS_MANUFACTURER:
          return this.$t('crossSelling.manufacturer')
        case ALGO_BS_MANUFACTURER_COLLECTION:
          return this.$t('crossSelling.manufacturerCollection')
      }

      if (this.crossSelling.crossSelling.type === ALGO_ALSO_BOUGHT) {
        return this.$t('crossSelling.typeAlsoBought')
      }

      return this.technicalName
    },
  },
  methods: {
    onIntersect(_, __, isIntersecting) {
      if (isIntersecting && this.technicalName && this.crossSelling.products) {
        this.broadcast(INTERCEPTOR_KEYS.VIEW_ITEM_LIST, {
          products: this.crossSelling.products,
          listId: this.technicalName,
          listName: this.technicalName,
        })
      }
    },
    handleSelectItem({ item, index }) {
      if (this.technicalName && this.crossSelling.products) {
        this.broadcast(INTERCEPTOR_KEYS.SELECT_PRODUCT_ITEM, {
          product: this.crossSelling.products?.[index] || item,
          index,
          listId: this.technicalName,
          listName: this.technicalName,
        })
      }
    },
  },
}
</script>
